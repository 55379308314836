import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewTemplateSelectorComponent } from './new-template-selector.component';

import { MwcTabsModule } from 'mwc/tabs';
import { MwcTagModule } from 'mwc/tag';
import { MwcIconModule } from 'mwc/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MwcSpinModule } from 'mwc/spin';
import { MwcButtonModule } from 'mwc/button';
import { MwcGridModule } from 'mwc/grid';
import { MwcMenuModule } from 'mwc/menu';
import { MwcSelectModule } from 'mwc/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MwcFormModule } from 'mwc/form';
import { MwcInputModule } from 'mwc/input';



@NgModule({
  declarations: [
    NewTemplateSelectorComponent
  ],
  imports: [
    CommonModule,
    MwcTabsModule,
    MwcTagModule,
    MwcIconModule,
    ScrollingModule,
    MwcButtonModule,
    MwcSpinModule,
    MwcGridModule,
    MwcMenuModule,
    MwcSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MwcFormModule,
    MwcInputModule,
    MwcTagModule
  ],
  exports: [NewTemplateSelectorComponent]
})
export class NewTemplateSelectorModule { }
