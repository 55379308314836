<!-- <mwc-tabset>
  <mwc-tab [mwcTitle]="all" (mwcClick)="onTabSelect('all')"></mwc-tab>
  <mwc-tab [mwcTitle]="web" (mwcClick)="onTabSelect('web')"></mwc-tab>
  <mwc-tab [mwcTitle]="news" (mwcClick)="onTabSelect('news')"></mwc-tab>
  <mwc-tab [mwcTitle]="social" (mwcClick)="onTabSelect('social')"></mwc-tab>
  <mwc-tab [mwcTitle]="media" (mwcClick)="onTabSelect('media')"></mwc-tab>
  <mwc-tab [mwcTitle]="advertising" (mwcClick)="onTabSelect('advertising')"></mwc-tab>
  <mwc-tab [mwcTitle]="emails" (mwcClick)="onTabSelect('emails')"></mwc-tab>
  <ng-template #all> <span class="mwc-text-sm-medium">All Templates </span> <mwc-tag
          [mwcColor]="tabs['all'] ? 'primary' : 'gray'">{{templatesCount['all']}}</mwc-tag></ng-template>
  <ng-template #web> <span class="mwc-text-sm-medium"> Web </span> <mwc-tag
          [mwcColor]="tabs['web'] ? 'primary' : 'gray'">{{templatesCount['web']}}</mwc-tag></ng-template>
  <ng-template #news> <span class="mwc-text-sm-medium"> News </span> <mwc-tag
          [mwcColor]="tabs['news'] ? 'primary' : 'gray'">{{templatesCount['news']}}</mwc-tag></ng-template>
  <ng-template #social> <span class="mwc-text-sm-medium"> Social </span> <mwc-tag
          [mwcColor]="tabs['social'] ? 'primary' : 'gray'">{{templatesCount['social']}}</mwc-tag></ng-template>
  <ng-template #media> <span class="mwc-text-sm-medium"> Media </span> <mwc-tag
          [mwcColor]="tabs['media'] ? 'primary' : 'gray'">{{templatesCount['media']}}</mwc-tag></ng-template>
  <ng-template #advertising> <span class="mwc-text-sm-medium"> Advertising </span> <mwc-tag
          [mwcColor]="tabs['advertising'] ? 'primary' : 'gray'">{{templatesCount['advertising']}}</mwc-tag></ng-template>
  <ng-template #emails> <span class="mwc-text-sm-medium"> Emails </span> <mwc-tag
          [mwcColor]="tabs['emails'] ? 'primary' : 'gray'">{{templatesCount['emails']}}</mwc-tag></ng-template>
</mwc-tabset> -->
<div mwc-row class="py-3">
  <div mwc-col  [mwcSpan]="6">
    <div style="width: 95%;margin: 0 auto 12px 0;">
      <mwc-input-group [mwcPrefix]="prefixIconSearch" >
        <input mwc-input [formControl]="searchField" type="text" class="mwc-text-sm-regular" placeholder="Search" mwcSize="small" />
      </mwc-input-group>
      <ng-template #prefixIconSearch>
        <span mwc-icon mwcIconfont="search-lg" style="font-size: 18px; color: #667085;"></span>
      </ng-template>
    </div>
    <ul mwc-menu [mwcSelectable]="true" class="template-menu" style="height: {{containerHeight}}" (mwcClick)="menuEvent($event)">
      <li mwc-menu-item mwcSelected>
        <a>
          <span mwc-icon mwcIconfont="layout-alt-02"></span>All Templates
        </a>
      </li>
      <li mwc-menu-item>
        <a>
          <span mwc-icon mwcIconfont="star-02"></span>Favorites
        </a>
      </li>
      <li mwc-submenu [mwcPaddingLeft]="14" mwcTitle="Marketing Function" [mwcIcon]="'announcement-02'">
          <ul>
            <li mwc-menu-item>
              <a>PR & Communication</a>
            </li>
            <li mwc-menu-item>
              <a>Product/Service Marketing</a>
            </li>
            <li mwc-menu-item>
              <a>Social Media</a>
            </li>
            <li mwc-menu-item>
              <a>Performance/Advertising</a>
            </li>
            <li mwc-menu-item>
              <a>Brand Marketing</a>
            </li>
            <li mwc-menu-item>
              <a>Content Marketing</a>
            </li>
          </ul>
      </li>
      <li mwc-submenu [mwcPaddingLeft]="14" mwcTitle="Content Type" [mwcIcon]="'pen-tool-02'">
        <ul>
          <li mwc-menu-item>
            <a>submenu</a>
          </li>
        </ul>
      </li>
      <li mwc-submenu [mwcPaddingLeft]="14" mwcTitle="Funnel" [mwcIcon]="'filter-funnel-02'">
        <ul>
          <li mwc-menu-item>
            <a>submenu</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div mwc-col [mwcSpan]="18" *ngIf="!isViewDetails">
    <mwc-spin [mwcSpinning]="isTemplateLoading" class="ps-3">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="mwc-text-lg-medium mwc-color-gray-900">All Templates</div>
        <div class="d-flex align-items-center gap-3">
          <span class="mwc-text-sm-medium">Filter by</span>
          <div>
            <mwc-select [mwcCustomTemplate]="defaultTemplate" [(ngModel)]="filterBy" (ngModelChange)="changeFilterBy($event)" [mwcDropdownClassName]="'mwc-xs-select-dropdown'" class="select-layout mwc-xs-select mwc-xxs-select mwc-text-sm-medium" style="width: 150px;" [mwcOptionHeightPx]="36">
              <mwc-option mwcCustomContent mwcLabel="Recommended" mwcValue="recommended">
                <div class="d-flex align-items-center justify-content-between" style="pointer-events: none;">
                  Recommended <span mwc-icon mwcIconfont="check" class="mwc-color-primary-600" style="font-size: 18px;" *ngIf="filterBy == 'recommended'"></span>
                </div>
              </mwc-option>
              <mwc-option mwcCustomContent mwcLabel="Recently used" mwcValue="recently-used">
                <div class="d-flex align-items-center justify-content-between" style="pointer-events: none;">
                  Recently used <span mwc-icon mwcIconfont="check" class="mwc-color-primary-600" style="font-size: 18px;" *ngIf="filterBy == 'recently-used'"></span>
                </div>
              </mwc-option>
              <mwc-option mwcCustomContent mwcLabel="Alphabetical order " mwcValue="alphabetical-order">
                <div class="d-flex align-items-center justify-content-between" style="pointer-events: none;">
                  Alphabetical order  <span mwc-icon mwcIconfont="check" class="mwc-color-primary-600" style="font-size: 18px;" *ngIf="filterBy == 'alphabetical-order'"></span>
                </div>
              </mwc-option>
            </mwc-select>
            <ng-template #defaultTemplate let-selected>
              {{selected.mwcLabel}}
            </ng-template>
          </div>
        </div>
      </div>
      <cdk-virtual-scroll-viewport #templateContainer itemSize="217" class="templates-container" style="height: {{containerHeight}}">
        <div class="templates-row" *cdkVirtualFor="let row of rowedData">
          <div class="template-card" (click)="onSelect(template)" *ngFor="let template of row">
            <div class="get-started-overlay">
              <button mwc-button mwcType="primary" mwcSize="small" (click)="initializeNewArticle('AI', template)">
                <span mwc-icon mwcIconfont="stars-02" style="font-size: 16px;"></span>
                <span class="mwc-text-sm-medium">Get Started</span>
              </button>
              <a mwc-button mwcType="link-color"  mwcSize="small" (click)="viewDetailsEvent(template)">
                <span mwc-icon mwcIconfont="eye" style="font-size: 16px;"></span>
                <span class="mwc-text-sm-medium">View Details</span>
              </a>
            </div>
            <mwc-tag *ngIf="template.isRecommended" class="tag" mwcColor="primary">Recommended</mwc-tag>
            <div class="template-card-heading">
                <div class="d-flex align-items-top justify-content-between w-100">
                  <div class="featured-icon {{template.iconColor}}">
                    <span mwc-icon mwcIconfont={{template.icon}}></span>
                  </div>
                  <div class="temp-star" *ngIf="template.isPublishable">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <g clip-path="url(#clip0_23206_10030)">
                      <path d="M9.87135 1.60996C10.0421 1.19932 10.6239 1.19932 10.7947 1.60996L12.8608 6.57744C12.9328 6.75056 13.0956 6.86885 13.2825 6.88383L18.6453 7.31376C19.0886 7.3493 19.2684 7.90256 18.9306 8.19189L14.8447 11.6919C14.7023 11.8139 14.6401 12.0053 14.6836 12.1876L15.9319 17.4208C16.0351 17.8534 15.5645 18.1954 15.1849 17.9635L10.5936 15.1592C10.4336 15.0615 10.2324 15.0615 10.0724 15.1592L5.48107 17.9635C5.10152 18.1954 4.63089 17.8534 4.73409 17.4208L5.98239 12.1876C6.0259 12.0053 5.96371 11.8139 5.82132 11.6919L1.73542 8.19189C1.39765 7.90256 1.57742 7.3493 2.02074 7.31376L7.38355 6.88383C7.57045 6.86885 7.73325 6.75056 7.80526 6.57744L9.87135 1.60996Z"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_23206_10030">
                      <rect width="20" height="20" fill="white" transform="translate(0.333008)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div class="template-card-title mwc-text-md-medium mwc-color-gray-800">{{template.title}}</div>
            </div>
            <div class="mwc-text-xs-regular mwc-color-gray-600"> {{template.description}} </div>
          </div>
        </div>
        <div *ngIf="rowedData.length == 0 && selectedMenuValue == 'Favorites'">
          <div class="empty-state-msg" style="text-align: center;">
            <div class="image-center">
              <img src="assets/images/Illustration-nofavorites.svg">
            </div>
            <div>
              <div class="mwc-text-lg-medium  mwc-color-gray-900 mb-2">No favorites yet</div>
              <div class="mwc-text-sm-regular mwc-color-gray-600">Simply click on the star icon on template page to add any template into <br/> your favorites. </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </mwc-spin>
  </div>
  <div mwc-col [mwcSpan]="18" *ngIf="isViewDetails">
    <mwc-spin [mwcSpinning]="isTemplateLoading" class="ps-3">
      <div class="view-details-sec">
        <div class="d-flex align-items-center justify-content-between" style="border-bottom: 1px solid #EAECF0;padding-bottom: 12px;margin-bottom: 12px;">
          <div>
            <a mwc-button mwcType="link-color"  mwcSize="small" (click)="viewDetailsBackEvent()">
              <span mwc-icon mwcIconfont="arrow-left" style="font-size: 16px;"></span>
              <span class="mwc-text-sm-medium">Back</span>
            </a>
          </div>
          <div>
            <button mwc-button mwcSize="small" mwcType="default" *ngIf="!viewData.isPublishable">
              <span mwc-icon mwcIconfont="star-01" style="font-size: 16px;"></span>
              Add as favorite
            </button>
            <button mwc-button mwcSize="small" mwcType="default" *ngIf="viewData.isPublishable">
              <div class="temp-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <g clip-path="url(#clip0_23206_10030)">
                  <path d="M9.87135 1.60996C10.0421 1.19932 10.6239 1.19932 10.7947 1.60996L12.8608 6.57744C12.9328 6.75056 13.0956 6.86885 13.2825 6.88383L18.6453 7.31376C19.0886 7.3493 19.2684 7.90256 18.9306 8.19189L14.8447 11.6919C14.7023 11.8139 14.6401 12.0053 14.6836 12.1876L15.9319 17.4208C16.0351 17.8534 15.5645 18.1954 15.1849 17.9635L10.5936 15.1592C10.4336 15.0615 10.2324 15.0615 10.0724 15.1592L5.48107 17.9635C5.10152 18.1954 4.63089 17.8534 4.73409 17.4208L5.98239 12.1876C6.0259 12.0053 5.96371 11.8139 5.82132 11.6919L1.73542 8.19189C1.39765 7.90256 1.57742 7.3493 2.02074 7.31376L7.38355 6.88383C7.57045 6.86885 7.73325 6.75056 7.80526 6.57744L9.87135 1.60996Z"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_23206_10030">
                  <rect width="20" height="20" fill="white" transform="translate(0.333008)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              Remove favorite
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="featured-icon {{viewData.iconColor}}">
              <span mwc-icon mwcIconfont={{viewData.icon}}></span>
            </div>
            <div class="template-card-title mwc-text-md-medium mwc-color-gray-800">{{viewData.title}}</div>
          </div>
          <div>
            <button mwc-button mwcSize="small" mwcType="primary" class="mwc-text-sm-medium"> Use Template </button>
          </div>
        </div>
        <div mwc-row style="margin-top: 40px;">
          <div mwc-col [mwcSpan]="20">
            <div class="mwc-text-lg-medium mwc-color-gray-700 mb-2">Overview</div>
            <div class="mwc-text-sm-regular mwc-color-gray-500">Create an insightful blog post designed to captivate your target audience while enhancing search engine visibility. This app will help you craft long-form content that seamlessly blends compelling storytelling with actionable insights. By maintaining a consistent voice and tone, you’ll not only communicate effectively with your readers, but also elevate your brand’s online presence.</div>

            <div class="mwc-text-lg-medium mwc-color-gray-700 mt-3 mb-2">Why use this template?</div>
            <div>
              <ul style="padding-left: 16px;">
                <li class="mwc-text-sm-regular mwc-color-gray-500"><span class="mwc-text-md-medium mwc-color-gray-700">Transform Ideas Quickly:</span>Utilize AI to efficiently turn a blog outline or idea into a comprehensive post, accelerating your content creation process.</li>
                <li class="mwc-text-sm-regular mwc-color-gray-500"><span class="mwc-text-md-medium mwc-color-gray-700">Ensure Consistent Branding:</span>Maintain a unified brand voice and tone across your posts, reinforcing your brand identity and ensuring clear communication.</li>
                <li class="mwc-text-sm-regular mwc-color-gray-500"><span class="mwc-text-md-medium mwc-color-gray-700">Captivate Your Audience:</span>Deliver relevant, well-written content that resonates with your audience, addressing their interests and needs while keeping them engaged.</li>
                <li class="mwc-text-sm-regular mwc-color-gray-500"><span class="mwc-text-md-medium mwc-color-gray-700">Boost SEO Performance:</span>Develop content that's meticulously optimized for search engines, attracting more organic traffic and amplifying your online visibility.</li>
              </ul>
            </div>
          </div>
          <div mwc-col [mwcSpan]="4">
            <div class="mwc-text-md-medium mwc-color-gray-700 mb-2">Categories</div>
            <ul class="p-0 m-0" style="list-style: none;">
              <li><mwc-tag [mwcColor]="'gray'" class="d-inline-flex align-items-center gap-1 m-0"><span mwc-icon mwcIconfont='arrow-up'></span>Category 1</mwc-tag></li>
              <li><mwc-tag [mwcColor]="'gray'" class="d-inline-flex align-items-center gap-1 m-0"><span mwc-icon mwcIconfont='arrow-up'></span>Category 2</mwc-tag></li>
              <li><mwc-tag [mwcColor]="'gray'" class="d-inline-flex align-items-center gap-1 m-0"><span mwc-icon mwcIconfont='arrow-up'></span>Category 3</mwc-tag></li>
            </ul>
          </div>
        </div>
      </div>
    </mwc-spin>
  </div>
</div>

