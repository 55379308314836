import { DamAssetModelResponse, DamAssetModelRquest } from "./dam";

export class GetArticleListRequest {
  selectors: Selector[];
  pageNumber: number;
  pageSize: number;
  profileId: number;
  orderBy: string;
  sortingOrder: string;
  status: string = '';//status, trash, draft and default blank means all
  seoScoreRange:number;
}
export class Selector {
  searchKey: string[];
  searchValue: string;
}

export class GetArticleListResponse {
  articles: ArticleDetail[];
  totalRecords: number;
  errorCode?: number;
  message: string;

}
export class ArticleDetail {
  articleId: number;
  templateId: number;
  templateName: string;
  title: string;
  categoryList: string[];
  seoScore: number;
  status: number;
  createddate: string;
  createdbyname: string;
  createdby: string;
  template: string;
  updateddate: string;
  slugUrl: string;
  module: string; 
  language:string;
  childArticles?: ArticleDetail[];
  expand?:boolean;
  parent?: ArticleDetail;
  translateLanguage?:string;
  parentArticleId:number;
}

export class GetCategoryListRequest {
  categoryname: string;
  status: number;
  profileId: number;
}
export class GetCategoryListResponse {
  categories: CategoryDetail[];
  totalRecords: number;
  errorCode?: number;
  message: string;
}
export class CategoryDetail {
  categoryId: number;
  categoryName: string;
  status: number;
  noOfPost: number;
  checked: boolean = false;
}
export class GetArticleByIdRequest {
  articleId: number;
  profileId: number;
}
export interface GetArticleByIdResponseType {
  article: ArticleType;
  errorCode?: number;
  message: string;
}
export class GetArticleByIdResponse {
  article: ArticleType = new Article();
  errorCode?: number = 0;
  message: string = "";
}

export interface ArticleType {
  articleId: number;
  templateId: number;
  targetAudienceId: number;
  brandVoiceId: number;
  templateTitle: string;
  title: string;
  slugUrl: string;
  authorName: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  damImage: DamAssetModelResponse;
  imageAltText: string;
  keyPhrase: string;
  seoValue: number;
  snippet: string;
  isFeaturedStory: number;
  isAllowComments: number;
  viewsCount: number;
  authorBiolink: string;
  alternateCanonical: string;
  status: number;
  categoryList: string[];
  tagsList: string[];
  createdBy: number;
  updatedBy: number;
  tone: string;
  language: string;
  module: string;
  image: string;
  imageFilePath: string;
  promptProperties: PromptProperties;
  languageDetail:DocumentLanguage;
  documentsDetail: DocumentDetail[];
  isPublishable: boolean;
  scheduledDateTime?: Date;
  sequenceNumber?: number;
  timeZone: string;
}

export class Article {
  articleId: number = 0;
  templateId: number = 0;
  targetAudienceId: number = 0;
  brandVoiceId: number = 0;
  templateTitle: string = "";
  title: string = "";
  slugUrl: string = "";
  authorName: string = "";
  content: string = "";
  metaTitle: string = "";
  metaDescription: string = "";
  damImage: DamAssetModelResponse = new DamAssetModelResponse();
  imageAltText: string = "";
  keyPhrase: string = "";
  seoValue: number = 0;
  snippet: string = "";
  isFeaturedStory: number = 0;
  isAllowComments: number = 0;
  viewsCount: number = 0
  authorBiolink: string = "";
  alternateCanonical: string = "";
  status: number = 0;
  categoryList: string[] = [];
  tagsList: string[] = [];
  createdBy: number = 0;
  updatedBy: number = 0;
  tone: string = "";
  language: string = "";
  module: string = "";
  image: string = "";
  imageFilePath: string = "";
  promptProperties: PromptProperties;
  languageDetail:DocumentLanguage;
  documentsDetail: DocumentDetail[];
  isPublishable: boolean;
  scheduledDateTime?: Date;
  sequenceNumber?: number;
  timeZone: string;
}

export class SaveArticleRequest {
  articleId: number;
  templateId: number;
  targetAudienceId: number;
  brandVoiceId: number;
  ignoreDetailsValidations: boolean;
  profileId: number;
  title: string;
  slugUrl: string;
  authorName: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  damImage: DamAssetModelRquest;
  imageAltText: string;
  keyPhrase: string;
  seoValue: number;
  snippet: string;
  isFeaturedStory: number;
  isAllowComments: number;
  viewsCount: number;
  authorBiolink: string;
  alternateCanonical: string;
  status: number;
  createdBy: number;
  updatedBy: number;
  categoryList: string[];
  tagsList: string[];
  tone: string;
  language: string;
  languageName: string;
  module: string;
  moduleId: number;
  publishType: number;
  siteId: number;
  publishContentUrl: string;
  image: string = "";
  imageFilePath: string = "";
  nonDamFeaturedImageUrl: string = "";
  promptProperties:PromptProperties;
  documentIds:string[] = [];
  documentDetail:DocumentDetail[] = [];
  isMLTranslationEnabled:boolean;
  faqsToSave:SaveFaq[] = [];
  faqsToDelete: DeleteFaq[] = [];
  isArticlePreviouslyPublished: boolean = false;
}

export class PromptProperties {
  intent: string = "";
  pillarIdea: string = "";
  coreTopic:string = "";
  keywords:string = "";
  generateFaqsRemainingAttempts:string = "";
  userContext:string = "";
  isManualGeneration:string = "false";
}
export class PublishContentResponse {
  id: number[];
  parentArticleId:number;
  errorCode?: number;
  message: string;
  errors: string[];
}
export class SaveArticleResponse {
  articleId: number;
  templateId: number;
  documentId:string;
  documentDetails:DocumentDetail[];
  errorCode?: number;
  message: string;
  errors: string[];
}
export class Category {
  categoryId: number;
  name: string;
  slugUrl: string;
  metaTitle: string;
  metaDescription: string;
  status: number = 1;
  profileId: number;
}


export class SaveCategoryRequest {
  categoryId: number;
  name: string;
  slugUrl: string;
  metaTitle: string;
  metaDescription: string;
  status: number = 1;
  profileId: number;
}
export class SaveCategoryResponse {
  errorCode?: number;
  message: string;
}

export class GetCategoryByIdRequest {
  categoryId: number;
  profileId: number;
}
export class GetCategoryByIdResponse {
  category: Category;
  errorCode?: number;
  message: string;
}
export class GetSEOWidgetResponse {
  seoScore: SEOScore[];
  averageSEOScore: number;
  totalArticles: number;
  errorCode?: number;
  message: string;
}
export class SEOScore {
  seoLabel: string;
  noOfPosts: string;
}

export interface CustomArticleErrorModel {
  ErrorHeading: string;
  ErrorSubHeading: string;
}

export class CustomArticleErrorModel {
  ErrorHeading: string = "";
  ErrorSubHeading: string = "";
}
export class UpdateArticleStatusRequest {
  articleIds: number[];
  actionName: string;
  actionStatus: boolean;
  profileId: number;
  actionValue: number;
  lastStatus: number;
}
export class UpdateArticleStatusResponse {
  errorCode?: number;
  message: string;
}

export interface SEOWidgetStoreStateType {
  totalBlogCount: number;
  overallScore: number;
  poorCount: number;
  goodCount: number;
  averageCount: number;
}

export class SEOWidgetStoreState {
  totalBlogCount: number = 0;
  overallScore: number = 0;
  poorCount: number = 0;
  goodCount: number = 0;
  averageCount: number = 0;
}
export class GetProfileListResponse {
  profiles: profileDetail[];
  errorCode?: number;
  message: string;
}
export class profileDetail {
  profileId: number;
  name: string;
  alias: string;
  nickName: string;
  externalAlias: string;
  thingId: string;
  featureIds: string[];
  moduleDataSourceIds: number[];
  siteUrl: string;
}

export class GetArticlePublishContentDetailRequest {
  articleId: number;
  profileId: number;
}

export class GetArticlePublishContentDetailResponse {
  articlePublishContentDetail: ArticlePublishContentDetail = new ArticlePublishContentDetail();
  errorCode?: number = 0;
  message: string = "";
}

export class ArticlePublishContentDetail {
  id: number;
  articleId: number;
  contentTypeId: number;
  publishedContentId: number;
  siteId: number;
  profileId: number;
  publishedURL: string;
  createdBy: number;
  modifiedBy: number;
  CreatedDate: Date;
}
export class DeleteCategoryAndReassignArticlesRequest {
  categoryIdToDelete: number;
  newCategoryId: number;
  profileId: number;
}
export class DeleteCategoryAndReassignArticlesResponse {
  errorCode?: number;
  message: string;
}

export class ScanContentRequest {
  profileId: number;
  scanType: string;
  content_Or_Url: string;
}

export class ScanContentResponse {
  requestId:string;
  creditsUsed:number;
  credits:number;
  detection:Detection;
  plagiarism:Plagiarism;
  readability:Readability;
  errorCode?: number;
  errorMessage: string;
}

export class Detection {
  avgOriginalScore:number;
  avgAIDetectScore:number;
  scoreBreakdown:DetectScoreBreakdown[]
}

export class DetectScoreBreakdown {
  originalScore:number;
  aIDetectScore:number;
  Text: string;
}

export class Plagiarism {
  totalScore:string;
  sourceCount:number;
  sources: PlagSource[];
}

export class PlagSource {
  phrase:string;
  matches:PlagMatch[];
}

export class PlagMatch {
  website:string;
  score:number;
  text:string;
}

export class Readability {
  fleschReadingEase:number;
  fleschGradeLevel:number;
  gunningFoxIndex:number;
  smogIndex:number;
  powersSumnerKearl:number;
  forcastGradeLevel:number;
  colemanLiauIndex:number;
  automatedReadabilityIndex:number;
  daleChallReadabilityGrade:number;
  spacheReadabilityGrade:number;
  linsearWriteGrade:number;
  letterCount:number;
  sentenceCount:number;
  uniqueWordCount:number;
  syllableCount:number;
  totalSyllables:number;
  averageSyllablesPerWord:number;
  wordsWithThreeSyllables:number;
  percentWordsWithThreeSyllables:number;
  longestSentence:string;
  paragraphCount:number;
  averageSpeakingTime:number;
  averageReadingTime:number;
  averageWritingTime:number;
  sentences:ReadableSentence[]
}

export class ReadableSentence {
  phrase:string;
  cleanPhrase:string;
  isVeryHard:boolean;
  isHard:boolean;
  wordsOver13Chars:any[];
  wordsOver4Syllables:string[];
  adverbs:string[];
}


export class GetArticleFaqsRequest {
  articleId:number[];
  sourceId:string[];
  isDeletedRequired: boolean;
}

export class GetArticleFaqsResponse {
  faqs: FaqDetail[];
  totalFAQs?: number;
  errorCode?: number;
  errorMessage: string;
}

export class FaqDetail {
  profileId?: number;
  dataSource: string;
  status: string;
  locationCount?: number;
  id?: string;
  partition?: number;
  businessThingId?: string;
  businessId?: number;
  questionId?: number;
  originDataSource: string;
  authorThingId: string;
  type: string;
  isStylizedText?: boolean;
  categories: string[];
  questionText: string;
  answer: FaqAnswer;
  createdDate?: Date;
  lastUpdatedDate?: Date;
  order?: number;
  sourceId: string;
  authorType: string;
  author: string;
  upvoteCount?: number;
  auxiliaryInfo: any;
}

export class FaqAnswer {
  constructor(){
    this.text = '';
    this.howToSteps = [];
    this.image = new FaqImage();
  }
  text: string;
  howToSteps: HowToStep[];
  image: FaqImage;
}

export class HowToStep {
  constructor(){
    this.name = '';
    this.description = '';
    this.image = new FaqImage();
  }
  name: string;
  description: string;
  image: FaqImage;
}

export class FaqImage {
  constructor() {
    this.caption = '';
    this.url = '';
    this.height = '0';
    this.width = '0';
    this.isDAMImage = false;
  }
  caption: string;
  url: string;
  height: string;
  width: string;
  isDAMImage: boolean
}

export class WidgetDateRangeInfo {
  fromDate: string;
  toDate: string;
}

export class SaveFaqsRequest {
  businessThingId?: string;
  businessId?: number;
  checkExist?: boolean;
  faqs: SaveFaq[];
}

export class DeleteFaqsRequest {
  businessThingId?: string;
  businessId?: number;
  permanentDelete?: boolean;
  faqs: DeleteFaq[];
}

export class SaveFaqsResponse {
  questionsText: string[];
  errorCode: number;
  errorMessage?: string;
}

export class DeleteFaqsResponse {
  errorCode: number;
  errorMessage?: string;
}

export class DeleteFaq {
  questionId: number;
  auxId:number;
  faqLocations: FaqLocations[];
}

export class FaqLocations {
  profileId: number;
  sourceId: string;
}

export class SaveFaq {
  faqDetail: SaveFaqDetail;
  faqsPublishDetail: SaveFaqPublishDetail[];
}

export class SaveFaqDetail {
  questionId?: number;
  originDataSource: string;
  dataSource: string;
  status: string;
  authorThingId: string;
  type: string;
  isStylizedText?: boolean;
  categories: string[];
  questionText: string;
  answer: FaqAnswer;
  auxiliaryInfo: any;
}

export class SaveFaqPublishDetail {
  questionId?: number;
  profileId?: number;
  partition?: number;
  dataSource: string;
  sourceId: string;
  status: string;
  author: Author;
  upvoteCount?: number;
  answers: SaveFaqPublishAnswer[];
  sourceCreatedDate?: string;
  sourceUpdatedDate?: string;
}

export class SaveFaqPublishAnswer {
  answerId?: number;
  sourceId: string;
  answer: FaqAnswer;
  author: Author;
  upvoteCount?: number;
  sourceCreatedDate?: string;
  sourceUpdatedDate?: string;
}

export class Author {
  constructor() {
    this.profilePhotoUrl = '';
    this.displayName = '';
    this.type = '';
  }
  profilePhotoUrl: string;
  displayName: string;
  type: string;
}

export class Faq{
  constructor() {
    this.questionText = '';
    this.answer = new FaqAnswer();
  }
  profileId?: number;
  dataSource: string;
  status: string;
  locationCount?: number;
  id?: number;
  partition?: number;
  businessThingId?: string;
  businessId?: number;
  questionId?: number;
  originDataSource: string;
  authorThingId: string;
  type: string;
  isStylizedText?: boolean;
  categories: string[];
  questionText: string;
  answer: FaqAnswer;
  createdDate?: Date;
  lastUpdatedDate?: Date;
  order?: number;
  sourceId: string;
  authorType: string;
  author: string;
  upvoteCount?: number;
  auxiliaryInfo: any;
  itemId:number;
  active:boolean;
  checked:boolean;
  isNewlyAdded:boolean;
  isEdited:boolean;
  isDeleted:boolean;
  mappingId:string;
}

export class PublishArticleFaqsRequest {
  userId: number;
  businessThingId: string ;
  businessId: number;
  publishFaqs: PublishFaq[] ;

  constructor(
    businessThingId: string = '',
    businessId: number = 0,
    permanentDelete: boolean = false
  ) {
    this.businessThingId = businessThingId;
    this.businessId = businessId;
    this.customInit();
  }

  customInit() {
    // Custom initialization logic
  }
}

export class PublishFaq {
  dataSourceId: string ;
  faqs: PublishFaqDetail[] ;
  profiles: FaqProfile[] ;
  sourceUrls: PublishFaqSourceUrl[] ;
}

export class PublishFaqDetail {
  faqId: string;
  questionId: number;
  status: string;
  sourceId: string ;
  type: string ;
  questionText: string ;
  categories: string[] ;
  publisherUserThingId: string ;
  authorPhotoUrl: any;
  author: string ;
  authortype: string ;
  stylizedText: boolean;
  answer: PublishAnswer ;
  operation: string;
  auxiliaryInfo:any;
  constructor() {
    this.operation = Operations.Add;
  }
}

export class PublishHowToQuestionAnswerContent {
  name: string ;
  description: string ;
  image: PublishFaqImage ;
}

export class PublishAnswer {
  answerId: string ;
  sourceId: string ;
  answerText: string ;
  answerRichText: string ;
  image: PublishFaqImage ;
  howToQuestionAnswerContents: PublishHowToQuestionAnswerContent[] ;
  publisherUserThingId: string ;
  authorPhotoUrl: string ;
  author: string ;
  authortype: string ;
}

export class PublishFaqImage {
  caption: string ;
  url: string ;
  height: string ;
  width: string ;
  isDAMImage: boolean ;
}

export class FaqProfile {
  profileId: number;
  profileThingId: string;

  toString(): string {
    return `ProfileId=${this.profileId}, ProfileThingId=${this.profileThingId}`;
  }
}

export class PublishFaqSourceUrl {
  sourceId: string ;
  categories: PublishFaqCategory[] ;
}

export class PublishFaqCategory {
  categoryId: string ;
  order: string ;
  faqs: PublishFaqDetail[] ;
}

export class Operations {
  static readonly Add: string = "_add";
  static readonly Edit: string = "_edit";
  static readonly Delete: string = "_delete";

  static readonly Operation: ReadonlyArray<string> = [Operations.Add, Operations.Edit, Operations.Delete];
}


export class PublishFaqsResponse {
  errorCode?: number;
  errorMessage: string;
}


export class BulkOperationFaqUrlRequest {
  faqUrls: BulkOperationFaqUrl[];
}

export class BulkOperationFaqUrl {
  id?: string; // Using string for UUID, which is commonly represented as a string
  profileAlias: string;
  siteUrl: string;
  dataSource: string;
  urls: BulkOperationUrl[];
  action?: string;
}

export class BulkOperationUrl {
  url: string;
  oldUrl: string;
  status: string;
}


export class BulkOperationFaqUrlResponse {
  errorCode?: number;
  errorMessage: string;
}

export class DocumentLanguage{
  languageCode:string;
  languageName:string;
  countryCode:string;
  id?: number;
}

export class DocumentDetail{
  documentId:string;
  documentLanguage: DocumentLanguage;
  status: string;
  articleId: number;
  publishContentUrl:string='';
}

export class TranslateContentRequest{
  articleId:number;
  translateFrom:DocumentDetail;
  TranslateTo: TranslateTo;
}

export class TranslateTo{
  documentDetails:DocumentDetail[];
}

export class TranslateContentResponse {
  documentsDetail: DocumentDetail[];
  errorCode?: number;
  message: string;
}

export class GetLanguagePackRequest{
  profileId:number;
}

export class GetLanguagePackResponse {
  documentLanguages:DocumentLanguage[];
  errorCode?: number;
  message: string;
}

export class GetTranslationStatusResponse {
  articleId: number;
  documentId: string;
  languageCode: string;
  status: string;
  success: boolean;
  errorMessage: string;
  errorCode: number;

  constructor() {
    this.articleId = 0;
    this.documentId = "";
    this.languageCode = "";
    this.status = "";
    this.success = false;
    this.errorMessage = "";
    this.errorCode = 0;
  }
}

export class GetTranslationStatusRequest {
  documentIds: string[];
  constructor() {
    this.documentIds = [];
  }
}

export class TranslatedContent {
  profileId: number;
  articleId: number;
  title: string;
  slugUrl: string;
  authorName: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  image: string;
  imageFilePath?: string;
  damImage?: DamAssetModelRquest;
  imageAltText: string;
  keyPhrase: string;
  seoValue: number;
  snippet: string;
  isFeaturedStory: number;
  isAllowComments?: number;
  viewsCount?: number;
  authorBiolink: string;
  alternateCanonical: string;
  status: number;
  categoryList: string[];
  tagsList: string[];
  tone: string;
  language: string;
  module: string;
  PromptProperties?: { [key: string]: string };
  faqsToSave:SaveFaq[] = [];
  faqsToDelete:DeleteFaq[] = [];

  constructor(
    profileId: number,
    articleId: number,
    title: string,
    slugUrl: string,
    authorName: string,
    content: string,
    metaTitle: string,
    metaDescription: string,
    image: string,
    imageFilePath: string | undefined,
    damImage: DamAssetModelRquest | undefined,
    imageAltText: string,
    keyPhrase: string,
    seoValue: number,
    snippet: string,
    isFeaturedStory: number,
    isAllowComments: number | undefined,
    viewsCount: number | undefined,
    authorBiolink: string,
    alternateCanonical: string,
    status: number,
    categoryList: string[],
    tagsList: string[],
    tone: string,
    language: string,
    module: string,
    PromptProperties: { [key: string]: string } | undefined,
    faqsToSave:SaveFaq[],
    faqsToDelete:DeleteFaq[]
  ) {
    this.profileId = profileId;
    this.articleId = articleId;
    this.title = title;
    this.slugUrl = slugUrl;
    this.authorName = authorName;
    this.content = content;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.image = image;
    this.imageFilePath = imageFilePath;
    this.damImage = damImage;
    this.imageAltText = imageAltText;
    this.keyPhrase = keyPhrase;
    this.seoValue = seoValue;
    this.snippet = snippet;
    this.isFeaturedStory = isFeaturedStory;
    this.isAllowComments = isAllowComments;
    this.viewsCount = viewsCount;
    this.authorBiolink = authorBiolink;
    this.alternateCanonical = alternateCanonical;
    this.status = status;
    this.categoryList = categoryList;
    this.tagsList = tagsList;
    this.tone = tone;
    this.language = language;
    this.module = module;
    this.PromptProperties = PromptProperties;
    this.faqsToSave = faqsToSave;
    this.faqsToDelete = faqsToDelete;
  }
}
export class GetTranslatedContentResponse {
  translatedContents: TranslatedContent[];
  errorCode?: number;
  message: string;

  constructor() {
    this.message = "";
    this.errorCode = 0;
    this.translatedContents = [];
  }
}

export class GetTranslatedContentRequest {
  documentIds: string[];
  constructor() {
    this.documentIds = [];
  }
}

export class PublishMultilingualContentRequest {
  documentDetails: DocumentDetail[];
  siteId:number;
  constructor() {
    this.documentDetails = [];
  }
}

export class PublishMultilingualContentResult {
  ids: number[];
  parentArticleId:number;
  documentDetails:DocumentDetail[];
  errorCode?: number;
  message: string;
  errors: string[];
}
export class PublishMultilingualContentResponse {
  result: PublishMultilingualContentResult[];

  constructor() {
    this.result = [];
  }
}

export class Template {
  title: string;
  isRecommended?: boolean;
  description: string;
  icon: string;
  iconColor: 'primary' | 'green' | 'yellow';
  type: 'web' | 'news' | 'social' | 'media' | 'advertising' | 'emails';
  templateId?: number;
  titleText: string;
  placeholderText: string;
  contentBriefText: string;
  isPublishable: boolean;
}
export class GetTemplateResponse {
  templates: Template[];
  totalRecords: number;
  errorCode?: number;
  message: string;

}

export class GetTargetAudienceRequest {
  targetAudienceId: number;
}
export class GetTargetAudienceListRequest {
  searchTerm: string="";
  filters: TargetAudienceListFilter[]=[];
  profileId: number;
  pageNumber: number=1;
  pageSize: number=20;
  orderBy: string ="createddate";
  sortingOrder: string="desc";
}

export class TargetAudienceListFilter {
  field: string;
  value: string;
}

export class GetBrandVoiceRequest {
  brandVoiceId: number;
}
export class GetBrandVoiceListRequest {
  searchTerm: string = "";
  filters: BrandVoiceListFilter[] = [];
  profileId: number;
  pageNumber: number = 1;
  pageSize: number = 20;
  orderBy: string ="createddate";
  sortingOrder: string = "desc";
}

export class BrandVoiceListFilter {
  field: string;
  value: string;
}



export class GetBrandVoiceListResponse {
  brandVoices: BrandVoice[];
  totalRecords: number;
  errorCode: number;
  errorMessage: string;
}

export class BrandVoice {
  brandVoiceId: number;
  businessId: number;
  title: string;
  author: number;
  isDefault: boolean;
  authorName: string;
  thingBrandVoice: ThingBrandVoice;
  lcid: number;
  isInactiveSinceDate?: Date;
  createdDate: Date;
  lastChangedDate: Date;
  brandVoiceProfileMapping: BrandVoiceProfileMapping;
}

export class ThingBrandVoice {
  thingJson: BrandVoiceThingJson;
}

export class BrandVoiceThingJson {
  title: string;
  contentSnippet: string;
  voiceTone: string;
  inclusions: string[];
  exclusions: string[];
  brandVoiceDocument: string;
}

export class BrandVoiceProfileMapping {
  name: string;
  totalCount: number;
}

export class GetBrandVoiceResponse extends BrandVoice {
  errorCode: number;
  errorMessage: string;
}

export class GetTargetAudienceListResponse {
  targteAudiences: TargetAudience[];
  totalRecords: number;
  errorCode: number;
  errorMessage: string;
}

export class TargetAudience {
  targetAudienceId: number;
  title: string;
  author?: number;
  isDefault: boolean;
  authorName: string;
  thingTargetAudience: ThingTargetAudience;
  lcid?: number;
  isInactiveSinceDate?: Date;
  createdDate: Date;
  lastChangedDate?: Date;
  targteAudienceProfileMapping?: TargetAudienceProfileMapping;
}

export class ThingTargetAudience {
  thingJson: TargetAudienceThingJson;
}

export class TargetAudienceThingJson {
  title?: string;
  gender?: string[] = [];
  ageGroup?: string[] = [];
  relationshipStatus?: string[] = [];
  industry?: string[] = [];
  interests?: string[] = [];
  intent?: string[] = [];
}

export class TargetAudienceProfileMapping {
  name: string;
  totalCount: number;
}

export class GetTargetAudienceResponse extends TargetAudience {
  errorCode: number;
  errorMessage: string;
}

export class CreateCustomerPortalRequest {
  businessId: number;
  actionType: string;
  returnUrl: string;
}

export class CreateCustomerPortalResponse {
  errorCode?: number;
  stripeUrl: string;
  errorMessage: string;
}

export class DefaultTargetAudienceForProfileRequest {
  targetAudienceId: number;
  businessId: number;
  profileIds: number[];
}

export class DefaultTargetAudienceForProfileResponse {
  targetAudienceId: number;
  businessId: number;
  profileIds: number[];
  errorCode: number;
  errorMessage: string;
}

export class DefaultBrandVoiceForProfileRequest {
  brandVoiceId: number;
  businessId: number;
  profileIds: number[];
}

export class DefaultBrandVoiceForProfileResponse {
  brandVoiceId: number;
  businessId: number;
  profileIds: number[];
  errorCode: number;
  errorMessage: string;
}
export class SchedulePublishRequest {
  contentId: number;
  templateId: number;
  profileId: number; 
  status: number;
  createdBy: number;
  updatedBy: number;  
  scheduledDateTime: Date;
  timeZoneId: string;
  siteId: number;
  cmsSiteUrl: string;
  businessId?: number;
  profileAlias: string;
  userName: string;
  userThingId: string;
  translationType?: string;
  isCMSDamEnabled: boolean;
}
export class SchedulePublishResponse {
  scheduleId: number;
  contentid: number;
  errorCode?: number;
  message: string;
  errors: string[];
}
export class CMSSiteInfo {
  cmsSiteUrl: string;
  siteId: number;
}
export class ScheduleCancelRequest {  
  contentid: number;
  sequenceNumber: number;
}
export class ScheduleCancelResponse {  
  errorCode?: number;
  message: string;
  errors: string[];
}
