<msc-asset-browser
[businessIds]="businessIds"
[maxAssetSelection]="maxAssetSelection"
[assetBrowserUIConfig]="assetBrowserUIConfig"
[assetTransforms]="assetTransforms"
[assetFilters]="assetFilters"
[showOpenAssetsLibrary]="showOpenAssetsLibrary"
(selectedAssets)="selectdDamAssets($event)"
>
</msc-asset-browser>
