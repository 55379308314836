import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, ActivatedRoute } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { MixpanelService } from './_shared/services/mixpanel/mixpanel.service';
import { StorageHelper } from './_utilities/storage-helper';
import { ContentStudioTrackingService } from './_shared/services/tracking/contentstudio-tracking.service';
import { TemplateHelperService } from './_shared/services/helper/template-selector/template-helper.service';
import { NewTemplateHelperService } from './_shared/services/helper/new-template-selector/new-template-helper.service';
import { MwcModalService } from 'mwc/modal';
import { CmsService } from './services/cms/cms.service';
import { isPlatformBrowser } from '@angular/common';
import { UserInfo } from './_shared/models/common';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('templateTitle', { read: TemplateRef }) templateSelectorTitle;
  @ViewChild('newTemplateTitle', { read: TemplateRef }) newTemplateSelectorTitle;
  isLoading: boolean = true;
  private _storageHelper: StorageHelper;
  mixPanelTrackingPaths = ['/dashboard', '/topic-ideas', '/manage-content/articles', '/settings', '/templates', '/manage-content/articles/manage-category']
  isSaasUser: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tracking: ContentStudioTrackingService,
    private modalService: MwcModalService,
    private templateHelperService: TemplateHelperService,
    private newtemplateHelperService: NewTemplateHelperService,
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this._storageHelper = new StorageHelper();
    this.router.events.subscribe((event: Event) => {
      this._storageHelper = new StorageHelper();
      this.navigationInterceptor(event);
    });
  }
  ngOnInit(): void {
    //storing business id and profile alias in local storage -> Start
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe(param => {
        console.log(param)
      });
      const urlParams = new URLSearchParams(window.location.search);
      const businessId = urlParams.get('businessId');
      const profileAlias = urlParams.get('profileAlias');
      if (businessId) {
        this._storageHelper.clear();
      }
      if (businessId) {
        this._storageHelper.setUserBusinessId(businessId);
      }
      else {
        if (this._storageHelper.user.bId === "") {
          this._storageHelper.setUserBusinessId('0');
        }
      }
      if (profileAlias) {
        this._storageHelper.setUserProfileAlias(profileAlias);
      }
      else {
        if (this._storageHelper.user.profileAlias === "") {
          this._storageHelper.setUserProfileAlias('');
        }
      }
    }
    this.commonService.getUserInfoValue().subscribe((res: UserInfo) => {
      if (res != null) {
        this.isSaasUser = res.isSaasUser;
      }
    });
    //storing business id and profile alias in local storage -> End
  }

  async navigationInterceptor(event: Event): Promise<void> {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.isLoading = false;
      const isTrackingPath = this.mixPanelTrackingPaths.includes(location.pathname);
      if (isTrackingPath) {
        const formattedPath = this.formatPath(location.pathname);
        this.tracking.trackEvent(
          'pageview', `${formattedPath}`
        );
        window.Appcues.page();
      }
    }
  }

  ngAfterViewInit(): void {
    this.templateHelperService.init(this.modalService, this.templateSelectorTitle);
    this.newtemplateHelperService.init(this.modalService, this.newTemplateSelectorTitle);
  }

  formatPath(path: string): string {
    const pathMap = {
      '/manage-content/articles': 'Articles',
      '/manage-content/articles/manage-category': 'Manage Category',
      '/dashboard': 'Dashboard',
      '/topic-ideas': 'Topic Ideas',
      '/settings': 'Settings',
      '/templates': 'Templates'
    };
    const formattedPath = pathMap[path] || path;
    return formattedPath
      .replace(/\//g, '')
      .replace(/-/g, ' ')
      .replace(/\b\w/g, match => match.toUpperCase());
  }

  ngOnDestroy() {
  }
}
