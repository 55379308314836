import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, throwError, timeout } from 'rxjs';
import { ArticleHttpTimeouts } from 'src/app/_shared/consts/article-constants';
import { GoogleKeywordPlannerResponseType } from 'src/app/_shared/models/google-keyword-planner';
import { OpenAIContextResponse, OpenAIResponse } from 'src/app/_shared/models/open-ai';



import { DamConfig } from 'src/app/_shared/models/common';
import { HelperService } from 'src/app/_shared/services/helper/helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import {
  GetArticleListRequest, GetArticleListResponse,
  GetCategoryListRequest, GetCategoryListResponse, GetArticleByIdRequest,
  GetArticleByIdResponse, SaveArticleRequest, SaveArticleResponse,
  GetCategoryByIdRequest, GetCategoryByIdResponse, SaveCategoryRequest,
  SaveCategoryResponse, GetSEOWidgetResponse, UpdateArticleStatusRequest,
  UpdateArticleStatusResponse,
  GetProfileListResponse,
  profileDetail,
  GetArticlePublishContentDetailRequest,
  GetArticlePublishContentDetailResponse,
  DeleteCategoryAndReassignArticlesResponse,
  DeleteCategoryAndReassignArticlesRequest,
  ScanContentRequest,
  ScanContentResponse,
  SaveFaqsRequest,
  SaveFaqsResponse,
  GetArticleFaqsRequest,
  GetArticleFaqsResponse,
  DeleteFaqsResponse,
  DeleteFaqsRequest,
  PublishFaqsResponse,
  BulkOperationFaqUrlRequest,
  BulkOperationFaqUrlResponse,
  PublishArticleFaqsRequest,
  TranslateContentRequest,
  TranslateContentResponse,
  GetLanguagePackRequest,
    GetLanguagePackResponse,
    GetTranslationStatusRequest,
    GetTranslationStatusResponse,
    GetTranslatedContentRequest,
    GetTranslatedContentResponse,
    PublishMultilingualContentRequest,
    GetTemplateResponse,
    GetTargetAudienceListRequest,
    GetBrandVoiceListRequest,
    GetBrandVoiceListResponse,
    GetTargetAudienceListResponse,
    GetBrandVoiceRequest,
    GetBrandVoiceResponse,
    GetTargetAudienceRequest,
    GetTargetAudienceResponse,
    CreateCustomerPortalRequest,
    CreateCustomerPortalResponse,
    DefaultBrandVoiceForProfileRequest,
    DefaultBrandVoiceForProfileResponse,
    DefaultTargetAudienceForProfileRequest,
    DefaultTargetAudienceForProfileResponse,
    SchedulePublishRequest,
    CMSSiteInfo,
    ScheduleCancelRequest
} from '../../_shared/models/article';
import { TopicIdeaPayloadForAIGeneration } from 'src/app/_shared/models/TopicIdeaPayloadForAIGeneration';
import { BrandVoicePayloadForAIGenerationType } from 'src/app/_shared/models/BrandVoicePayloadForAIGeneration';
import { GenerateArticleByAIRequestModel } from 'src/app/_shared/models/GenerateArticleByAIRequestModel';
import { GenerateFaqsByArticleByAIRequestModel } from 'src/app/_shared/models/GenerateFaqsByArticleByAIRequestModel';


@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  // Private Properties  

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,
  ) {
    this._storageHelper = new StorageHelper();
  }

  //get article list
  public getArticleList<T>(request: GetArticleListRequest): Observable<GetArticleListResponse> {
    return this.http.post<GetArticleListResponse>(
      `${this.baseUrl}api/Article/GetArticleList`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //get category list
  public getCategoryList<T>(request: GetCategoryListRequest): Observable<GetCategoryListResponse> {
    return this.http.post<GetCategoryListResponse>(
      `${this.baseUrl}api/Article/GetCategoryList`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //async method which returns promise - TODO:later remove
  //get category list
  public async getCategoryListAsync<T>(request: GetCategoryListRequest): Promise<GetCategoryListResponse> {
    return await lastValueFrom(this.http.post<GetCategoryListResponse>(
      `${this.baseUrl}api/Article/GetCategoryList`,
      JSON.stringify(request),
      { headers: this.headers }
    ));
  }

  //get article by ID
  public getArticleById<T>(request: GetArticleByIdRequest): Observable<GetArticleByIdResponse> {
    return this.http.post<GetArticleByIdResponse>(
      `${this.baseUrl}api/Article/GetArticleById`,
      JSON.stringify(request),
      { headers: this.headers }
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //save article 
  public saveArticle<T>(request: SaveArticleRequest): Observable<SaveArticleResponse> {
    return this.http.post<SaveArticleResponse>(
      `${this.baseUrl}api/Article/SaveArticle`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(error);
        }));
  }

  public publishMultilingualContent<T>(request: PublishMultilingualContentRequest) {
    return this.http.post(
      `${this.baseUrl}api/cmscontent/publishMultilingualContent`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(error);
        }));
  }

  //save article 
  public publishContent<T>(request: SaveArticleRequest) {
    return this.http.post(
      `${this.baseUrl}api/cmscontent/publishContent`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(error);
        }));
  }

  public updateArticleStatus<T>(request: UpdateArticleStatusRequest) {

    return this.http.post<UpdateArticleStatusResponse>(
      `${this.baseUrl}api/Article/UpdateArticleStatus`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //getCategoryById
  public getCategoryById<T>(request: GetCategoryByIdRequest): Observable<GetCategoryByIdResponse> {
    return this.http.post<GetCategoryByIdResponse>(
      `${this.baseUrl}api/Article/GetCategoryById`,
      JSON.stringify(request),
      { headers: this.headers });
  }
  //save category
  public saveCategory<T>(request: SaveCategoryRequest): Observable<SaveCategoryResponse> {
    return this.http.post<SaveCategoryResponse>(
      `${this.baseUrl}api/Article/SaveCategory`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //get widget data
  public getSEOWidget<T>(profileId: number): Observable<GetSEOWidgetResponse> {
    return this.http.post<GetSEOWidgetResponse>(
      `${this.baseUrl}api/Article/GetSEOWidget?profileId=` + profileId,
      '',
      { headers: this.headers }
    );
  }

  //getProfileList
  public getProfileList<T>(): Observable<GetProfileListResponse> {

    return this.http.post<GetProfileListResponse>(
      `${this.baseUrl}api/Article/GetProfileList`,
      '',
      { headers: this.headers }
    );
  }
  //get generated keyphrases from AI- TODO: Change this later
  public generateKeyphrasesFromContext<T>(context: string, inputTone: string, inputLanguage: string, aiEngine: string = ""): Observable<OpenAIResponse> {
    let generateArticleByAIRequestModel = new GenerateArticleByAIRequestModel();
    generateArticleByAIRequestModel.Mode = "_completion";
    generateArticleByAIRequestModel.Section = "Keyphrase Generation";
    generateArticleByAIRequestModel.FieldId = "none"
    generateArticleByAIRequestModel.InputContext = "none";
    generateArticleByAIRequestModel.InputTone = inputTone;
    generateArticleByAIRequestModel.InputLanguage = inputLanguage;
    generateArticleByAIRequestModel.Context = context;
    generateArticleByAIRequestModel.AiEngine = aiEngine;
    if ((inputTone == undefined || inputTone == null || inputTone.length === 0) && (inputLanguage == undefined || inputLanguage == null || inputLanguage.length === 0)) {
      generateArticleByAIRequestModel.InputTone = "none";
      generateArticleByAIRequestModel.InputLanguage = "none";
    }
    return this.http.post<OpenAIResponse>(
      `${this.baseUrl}api/Article/generateContentByAI`,
      JSON.stringify(generateArticleByAIRequestModel),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_KEYPHRASE_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //get generated post body from AI- TODO: Change this later
  public generatePostBodyFromContext<T>(keyphrase: string, inputContext: string, inputTone: string, inputLanguage: string, aiEngine: string = ""): Observable<OpenAIContextResponse> {
    let generateArticleByAIRequestModel = new GenerateArticleByAIRequestModel();
    generateArticleByAIRequestModel.Mode = "_completion";
    generateArticleByAIRequestModel.Section = "Body";
    generateArticleByAIRequestModel.FieldId = "none"
    generateArticleByAIRequestModel.InputContext = inputContext;
    generateArticleByAIRequestModel.InputTone = inputTone;
    generateArticleByAIRequestModel.InputLanguage = inputLanguage;
    generateArticleByAIRequestModel.Context = keyphrase;
    generateArticleByAIRequestModel.AiEngine = aiEngine;
    if ((inputTone == undefined || inputTone == null || inputTone.length === 0) && (inputLanguage == undefined || inputLanguage == null || inputLanguage.length === 0)) {
      generateArticleByAIRequestModel.InputTone = "none";
      generateArticleByAIRequestModel.InputLanguage = "none";
    }
    return this.http.post<OpenAIContextResponse>(
      `${this.baseUrl}api/Article/GenerateContentByAI`,
      JSON.stringify(generateArticleByAIRequestModel),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_POSTBODY_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //get generated post body from AI- TODO: Change this later
  public getKeyphraseFromGoogleKeywordsPlanner<T>(keyPhrase: string): Observable<GoogleKeywordPlannerResponseType> {
    return this.http.get<GoogleKeywordPlannerResponseType>(
      `${this.baseUrl}api/Article/GetKeyphraseFromGoogleKeywordsPlanner?keyPhrase=${keyPhrase}`,
      { responseType: "json" }
    ).pipe(timeout(ArticleHttpTimeouts.GKP_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //get generated individual field from AI
  public generateIndividualFieldUsingFieldID<T>(keyphrase: string, fieldId: string, inputTone: string, inputLanguage: string, aiEngine: string = ""): Observable<OpenAIResponse> {
    let generateArticleByAIRequestModel = new GenerateArticleByAIRequestModel();
    generateArticleByAIRequestModel.Mode = "_completion";
    generateArticleByAIRequestModel.Section = "Individual Fields";
    generateArticleByAIRequestModel.FieldId = fieldId;
    generateArticleByAIRequestModel.InputContext = "none";
    generateArticleByAIRequestModel.InputTone = inputTone;
    generateArticleByAIRequestModel.InputLanguage = inputLanguage;
    generateArticleByAIRequestModel.Context = keyphrase;
    generateArticleByAIRequestModel.AiEngine = "_openAI";
    if ((inputTone == undefined || inputTone == null || inputTone.length === 0) && (inputLanguage == undefined || inputLanguage == null || inputLanguage.length === 0)) {
      generateArticleByAIRequestModel.InputTone = "none";
      generateArticleByAIRequestModel.InputLanguage = "none";
    }
    return this.http.post<OpenAIResponse>(
      `${this.baseUrl}api/Article/generateContentByAI`,
      JSON.stringify(generateArticleByAIRequestModel),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_INDIVIDUAL_FIELD_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //get generated post body for TopicIdea Screen
  public generateContentByAIForTopicIdea<T>(topicIdeaPayloadForAIGeneration: TopicIdeaPayloadForAIGeneration): Observable<OpenAIContextResponse> {
    return this.http.post<OpenAIContextResponse>(
      `${this.baseUrl}api/Article/GenerateContentByAIForTopicIdea`,
      JSON.stringify(topicIdeaPayloadForAIGeneration),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_POSTBODY_FORTOPICIDEA_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  //get generated brand voice document from AI
  public generateBrandVoiceDocumentUsingAI<T>(brandVoicePayloadForAIGeneration: BrandVoicePayloadForAIGenerationType): Observable<OpenAIResponse> {
    return this.http.post<OpenAIResponse>(
      `${this.baseUrl}api/Article/GenerateBrandVoiceDocumentByAI`,
      JSON.stringify(brandVoicePayloadForAIGeneration),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_BRANDVOICE_DOCUMENT_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }

  private _profileList = new BehaviorSubject<profileDetail[]>(undefined);
  public get profileList(): profileDetail[] {
    return this._profileList.value;
  }
  public set profileList(obj: profileDetail[]) {
    this._profileList.next(obj);
  }
  public get profileListObjectObservable(): Observable<profileDetail[]> {
    return this._profileList.asObservable();
  }

  public getArticlePublishContentDetail<T>(request: GetArticlePublishContentDetailRequest): Observable<GetArticlePublishContentDetailResponse> {
    return this.http.post<GetArticlePublishContentDetailResponse>(
      `${this.baseUrl}api/Article/GetArticlePublishContentDetail`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //delete category and reassign new category to the mapped articles
  public deleteCategoryAndReassignArticles<T>(request: DeleteCategoryAndReassignArticlesRequest): Observable<DeleteCategoryAndReassignArticlesResponse> {
    return this.http.post<DeleteCategoryAndReassignArticlesResponse>(
      `${this.baseUrl}api/Article/DeleteCategoryAndReassignArticles`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  private _isContentIntelligenceEnabled = new BehaviorSubject<boolean>(false);
  public get IsContentIntelligenceEnabled(): boolean {
    return this._isContentIntelligenceEnabled.value;
  }
  public set IsContentIntelligenceEnabled(obj: boolean) {
    this._isContentIntelligenceEnabled.next(obj);
  }
  public get IsContentIntelligenceEnabledObjectObservable(): Observable<boolean> {
    return this._isContentIntelligenceEnabled.asObservable();
  }

  //scan the body of article
  public ScanContent<T>(request: ScanContentRequest): Observable<ScanContentResponse> {
    return this.http.post<ScanContentResponse>(
      `${this.baseUrl}api/Article/ScanContent`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Save Faqs for an article
  public SaveFaqs<T>(request: SaveFaqsRequest): Observable<SaveFaqsResponse> {
    return this.http.post<SaveFaqsResponse>(
      `${this.baseUrl}api/Article/SaveFaqs`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Delete Faqs for an article
  public DeleteFaqs<T>(request: DeleteFaqsRequest): Observable<DeleteFaqsResponse> {
    return this.http.post<DeleteFaqsResponse>(
      `${this.baseUrl}api/Article/DeleteFaqs`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Faqs for an article
  public GetArticleFaqs<T>(request: GetArticleFaqsRequest): Observable<GetArticleFaqsResponse> {
    return this.http.post<GetArticleFaqsResponse>(
      `${this.baseUrl}api/Article/GetArticleFaqs`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Generate Faqs By Article from AI
  public generateFaqsByArticleUsingAI<T>(generateFaqsByArticleByAI: GenerateFaqsByArticleByAIRequestModel): Observable<OpenAIResponse> {
    return this.http.post<OpenAIResponse>(
      `${this.baseUrl}api/Article/GenerateFaqsByArticle`,
      JSON.stringify(generateFaqsByArticleByAI),
      { headers: this.headers }
    ).pipe(timeout(ArticleHttpTimeouts.GENERATE_ARTICLE_FAQS_TIMEOUT),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }));
  }


  //Publish Faqs for an article
  public PublishFaqs<T>(request: PublishArticleFaqsRequest): Observable<PublishFaqsResponse> {
    return this.http.post<PublishFaqsResponse>(
      `${this.baseUrl}api/Article/PublishFaqs`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //BulkOperationFaqUrl for an article
  public BulkOperationFaqUrl<T>(request: BulkOperationFaqUrlRequest): Observable<BulkOperationFaqUrlResponse> {
    return this.http.post<BulkOperationFaqUrlResponse>(
      `${this.baseUrl}api/Article/BulkOperationFaqUrl`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //get Translation Status
  public getTranslationStatus<T>(request: GetTranslationStatusRequest): Observable<GetTranslationStatusResponse> {
    return this.http.post<GetTranslationStatusResponse>(
      `${this.baseUrl}api/Article/GetTranslationStatus`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //get Translated Content
  public getTranslatedContent<T>(request: GetTranslatedContentRequest): Observable<GetTranslatedContentResponse> {
    return this.http.post<GetTranslatedContentResponse>(
      `${this.baseUrl}api/Article/GetTranslatedContent`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Translate Content of an article
  public TranslateContent<T>(request: TranslateContentRequest): Observable<TranslateContentResponse> {
    return this.http.post<TranslateContentResponse>(
      `${this.baseUrl}api/Article/TranslateContent`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Translate Content of an article
  public SaveArticleContentState<T>(request: SaveArticleRequest): Observable<SaveArticleResponse> {
    return this.http.post<SaveArticleResponse>(
      `${this.baseUrl}api/Article/SaveArticleContentState`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }
  // Get all Templates
  public GetTemplateList<T>(): Observable<GetTemplateResponse> {
    return this.http.post<GetTemplateResponse>(
      `${this.baseUrl}api/Article/GetTemplateList`,
      null,
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(() => error);
        }));
  }

  //Get Target Audience List
  public GetTargetAudienceList<T>(request: GetTargetAudienceListRequest): Observable<GetTargetAudienceListResponse> {
    return this.http.post<GetTargetAudienceListResponse>(
      `${this.baseUrl}api/Article/GetTargetAudienceList`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Brand Voice List
  public GetBrandVoiceList<T>(request: GetBrandVoiceListRequest): Observable<GetBrandVoiceListResponse> {
    return this.http.post<GetBrandVoiceListResponse>(
      `${this.baseUrl}api/Article/GetBrandVoiceList`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Target Audience 
  public GetTargetAudience<T>(request: GetTargetAudienceRequest): Observable<GetTargetAudienceResponse> {
    return this.http.post<GetTargetAudienceResponse>(
      `${this.baseUrl}api/Article/GetTargetAudience`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Brand Voice 
  public GetBrandVoice<T>(request: GetBrandVoiceRequest): Observable<GetBrandVoiceResponse> {
    return this.http.post<GetBrandVoiceResponse>(
      `${this.baseUrl}api/Article/GetBrandVoice`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Brand Voice 
  public createCustomerPortal<T>(request: CreateCustomerPortalRequest): Observable<CreateCustomerPortalResponse> {
    return this.http.post<CreateCustomerPortalResponse>(
      `${this.baseUrl}api/Article/CreateCustomerPortal`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Assign Default Target Audience For Profile
  public assignDefaultTargetAudienceForProfile<T>(request: DefaultTargetAudienceForProfileRequest): Observable<DefaultTargetAudienceForProfileResponse> {
    return this.http.post<DefaultTargetAudienceForProfileResponse>(
      `${this.baseUrl}api/Article/AssignDefaultTargetAudienceForProfile`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Assign Default Brand Voice For Profile
  public assignDefaultBrandVoiceForProfile<T>(request: DefaultBrandVoiceForProfileRequest): Observable<DefaultBrandVoiceForProfileResponse> {
    return this.http.post<DefaultBrandVoiceForProfileResponse>(
      `${this.baseUrl}api/Article/AssignDefaultBrandVoiceForProfile`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Schedule Article 
  public SchedulePublishContent<T>(request: SchedulePublishRequest) {
    return this.http.post(
      `${this.baseUrl}api/Article/SchedulePublishContent`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(() => error);
        }));
  }
  public GetCMSSiteInfo<T>(): Observable<CMSSiteInfo> {
    return this.http.get<CMSSiteInfo>(
      `${this.baseUrl}api/Article/GetCMSSiteInfo`,
      { responseType: "json" }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(() => error);
      }));
  }
  //Schedule Cancel
  public SchedulePublishCancel<T>(request: ScheduleCancelRequest) {
    return this.http.post(
      `${this.baseUrl}api/Article/SchedulePublishCancel`,
      JSON.stringify(request),
      { headers: this.headers }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          return throwError(() => error);
        }));
  }
}
